
.comment-layout {
  //background-color: red !important;

  &.ant-layout {
    width: 100%;
    height: 100vh !important;
    overflow-x: hidden;
  }

  .site-layout {
    width: 100% !important;

    .layout-header {
      //background: red;
    }
  }

  .ant-layout-sider {

  }

  .ant-layout-sider {
    //background: #fbfbfb;
    padding: 0 20px;
    //background-color: red !important;
    background: var(--layout-bg-color);

    .ant-layout-sider-children {
      position: relative;
      //background-color: red;

      .logo {
        height: 80px;
        overflow: hidden;
        padding: 20px;
        background: var(--layout-bg-color);
        //background: #fbfbfb;
        //background-color: red;
        margin: 0 -20px 10px;
        border-bottom: 1px solid var(--gray);
      }

      img {
        height: 30px;
      }
    }

    .ant-menu-item {
      border: 1px solid var(--main-border-color);
    }
  }

  .ant-layout-header {
    padding: 0 20px 0 0;
    height: 80px;
    //background: #fff;
    background: var(--layout-bg-color);
    border-bottom: 1px solid var(--gray);
    z-index: 5;

    .trigger {
      padding: 10px;
      font-size: 18px;
    }

    .trigger:hover,
    .logout:hover {
      color: var(--main-color);
    }

    .userinfo,
    .home-header-btns {
      display: flex;

      .ant-btn,
      .logout {
        margin-left: 20px;

        .anticon {
          margin-right: 5px;
        }
      }
    }
  }

  .ant-layout-content {
    padding: 30px 30px 30px 20px;
    //background: #fff;
    background: var(--layout-bg-color);
    //background-color: red;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.ant-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
    }

    .ant-dropdown-menu-item:hover {
      background: #8517e6;
    }
  }
}