.loadingComp {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#nprogress .spinner {
  height: 0 !important;
  display: none !important;
}
#nprogress .bar {
  background-color: var(--main-bg);
  position: fixed;
  top: 0;
  height: 3px;
  width: 100%;
  z-index: 99;
}