
* {
    box-sizing: border-box;
}

body,
p,
h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
    display: inline-block;
}

html,
body {
    width: 100%;
    height: 100%;
}

i {
    font-style: normal;
    color: var(--main-bg-active);
}

img {
    vertical-align: middle;
}

/* 覆盖antd */
.anticon {
    cursor: pointer;
}

.ant-menu {
    border-inline-end: 0 !important;

    .ant-menu-submenu-selected {
        /*background: var(--main-bg);*/
    }
}

.ant-btn-default.ant-btn-background-ghost {
    background-color: var(--main-bg-active);
    color: #fff;

    &:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: var(--main-bg-active);
        color: var(--gray-text);
    }
}

.ant-btn-dashed {
    border: 1px solid var(--gray-border);
}

.ant-btn-dashed.ant-btn-background-ghost {
    border: 0px solid;
}

.ant-modal-content {
    .ant-modal-close-x {
        font-size: 22px;
    }

    .ant-modal-header {
        padding-bottom: 20px;
        border-bottom: 1px solid var(--main-bg);

        .ant-modal-title {
            padding: 0 20px;
        }
    }

    .ant-modal-body {
        padding-top: 20px;

        .flex-column .anticon {
            font-size: 32px;
        }
    }

    .ant-modal-footer {
        text-align: center;
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 20px;
    }
}

.ant-tag {
    font-size: 12px;
}

.ant-form-item-label {
    font-weight: 600;
}

.ant-notification {
    top: 40% !important;
    right: 50% !important;

    .ant-notification-notice-wrapper {
        transform: translate(50%, -50%) !important;
    }

    .ant-notification-notice-content {
        .ant-notification-notice-with-icon {
            text-align: center;

            .anticon {
                position: unset;
                font-size: 32px;

                &.ant-notification-notice-icon-success {
                    color: #26C3F5;
                }

                &.ant-notification-notice-icon-info {
                    color: #FFB800;
                }
            }

            .ant-notification-notice-message {
                margin-top: 5px;
                margin-bottom: 0;
                margin-inline-start: 0 !important;
            }

            .ant-notification-notice-description {
                margin-top: 5px;
                margin-inline-start: 0 !important;
            }
        }
    }
}

.ant-pagination {
    .ant-pagination-item {
        background-color: var(--gray);
        border: 1px solid var(--gray-border);

        &:hover {
            border-color: var(--main-bg-active);
        }
    }

    .ant-pagination-item-link {
        background-color: var(--gray) !important;

        .anticon {
            font-size: 16px;
            cursor: inherit;
        }

        &:hover {
            color: #fff;
        }
    }

    .ant-pagination-item-active {
        background-color: var(--main-bg);

        a {
            color: #fff;

            &:hover {
                color: #fff;
            }
        }
    }

    .ant-pagination-options-quick-jumper {
        input {
            background-color: var(--gray);
            border: 1px solid var(--gray-border);
        }
    }
}


.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-c {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex1 {
    flex: 1;
}

.flex-t {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.flex-l {
    display: flex;
    justify-content: left;
    align-items: center;
}

.flex-r {
    display: flex;
    justify-content: right;
    align-items: center;
}


.flex-c {
    display: flex;
    justify-content: center;
    align-items: center;
}


.flex-c-l {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: flex-start;
}

.flex-c-c {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}


.ml-10 {
    margin-left: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-8 {
    color: #000000CC;
    font-size: 18px;
    margin-bottom: 32px;
}

.mb-30 {
    margin-bottom: 30px
}

.mr-10 {
    margin-right: 10px;
}


.weight5 {
    font-weight: 500;
}


.h0 {
    height: 0;
}

.text-r {
    text-align: right;
}

.text-c {
    text-align: center;
}

.w100 {
    width: 100%;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.h100 {
    height: 100%;
}

.ml10 {
    margin-left: 10px;
}

.mr10 {
    margin-right: 10px;
}

.mt10 {
    margin-top: 10px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb30 {
    margin-bottom: 30px;
}

.pd10 {
    padding: 10px;

}

.pd20 {
    padding: 20px;
}

.h2 {
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
}

.h4 {
    font-size: 18px;
    font-weight: 600;
    color: var(--main-bg);
}

.p4 {
    color: #000000CC;
    font-size: 18px;
    margin-bottom: 32px;
}

.h5 {
    font-size: 14px;
    font-weight: 500;
    color: var(--main-bg);
}

.p5 {
    color: #000000CC;
    font-size: 12px;
    margin-bottom: 12px;
}

.h6 {
    font-size: 12px;
    color: #00000099;
}

.fw5 {
    font-weight: 600;
}

.bdr-lg {
    border-radius: 20px;
}

.bdr-sm {
    border-radius: 8px;
}

.bg-gray {
    background-color: var(--gray);
}

.text-20 {
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
}

.bg-red {
    background-color: red;
}

.bg-blue {
    background-color: blue;
}


.btn-black {
    color: black;
    background-color: white;
}

/**
    display 相关
 */
.flex-1 {
    flex: 1 1 0;
}

.dis-flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.justify-event {
    justify-content: space-evenly;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-end {
    justify-content: flex-end;
}

.justify-start {
    justify-content: flex-start;
}

.items-start {
    align-items: flex-start;
}

.items-end {
    align-items: flex-end;
}

.items-center {
    align-items: center;
}

.items-baseline {
    align-items: baseline;
}

.items-stretch {
    align-items: stretch;
}

.float-r {
    float: right;
}