.error404 {
  width: 100%;
  height: 100vh;

  overflow: hidden;

  #error404Canvas {
    opacity: 0.5;
    width: 100%;
    height: 100vh;
  }

  .error_main {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .errorText {
    margin-left: 10px;

    h1 {
      background-clip: text;
      -webkit-background-clip: text;
      font-size: 200px;
      letter-spacing: 0.25vw;
      margin: 0;
      font-family: myFont;
    }

    h2 {
      font-size: 50px;
      font-weight: 600;
      margin: 10px 0;
    }

    p {
      font-size: 25px;

    }
  }

  @font-face {
    font-family: myFont;
    // todo 缺少字体文件
    //src: url('../../assets/fonts/Technology.ttf')
  }

  button {
    --color: #12233f;
    font-family: inherit;
    display: inline-block;
    width: 8em;
    height: 2.6em;
    margin: 20px 0;
    position: relative;
    overflow: hidden;
    border: none;
    transition: color .5s;
    z-index: 1;
    font-size: 17px;
    font-weight: 500;
    color: var(--color);
    border-radius: 20px;
  }

  button:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 150px;
    width: 200px;
    border-radius: 50%;
  }

  button:hover {
    cursor: pointer;
    color: #fff;
  }

  button:before {
    top: 100%;
    left: 100%;
    transition: all .7s;
  }

  button:hover:before {
    top: -30px;
    left: -30px;
  }

  button:active:before {
    background: #475a79;
    transition: background 0s;
  }
}