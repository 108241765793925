@use "assets/style/common.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --main-color: #dd8dee;
  --main-text-color: #de8eef;
  --main-bg: #2e3246;
  --main-bg-light: #2e3246D9;
  --main-bg-hover: #ddf76f;
  --main-bg-active: #8517e6;
  --main-border-color: rgba(221, 141, 238, 0.1);
  --gray: #0000000D;
  --gray-border: #2E324633;
  --gray-text: #00000080;
  --primary-button-gradient: linear-gradient(-45deg, var(--main-color), var(--main-bg-hover));
  --main-gradient: linear-gradient(to right, #f6fdd5, var(--main-bg-hover));
  --main-bg-gradient: linear-gradient(-45deg, rgba(221, 141, 238, 0.2), rgb(238, 215, 222) 45%, var(--main-bg-hover));

  //全局背景色，旧项目通过configureProvider配置的被覆盖了，并且修改的话，要改动的多，还是采用覆盖的方式，但是将这个颜色设置成一个变量
  --layout-bg-color: #eeeeee;
}

html,
body {
  margin: 0;
  height: 100%;
}

#root {
  min-height: 100vh;
  width: 100vw;
}
